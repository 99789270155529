// Fixes for fullcalendar css
// These are here cause we want the calendar to look a certain way,
// and the only way to do it is with this custom css.
.fc-timegrid-slot {
  height: 4em !important; /* 1.5em by default */
  border-bottom: 0 !important;
}

.fc-event {
  text-align: left !important;
}

.fc-event-main {
  overflow: hidden;
  white-space: nowrap;
}

.fc-daygrid-day {
  overflow: hidden;
}

.fc-bg-event {
  color: white; /* To make the title text white */
}

.blocks-header {
  background-color: $yellow;
  color: $white;
  padding: 10px;
  font-weight: bold;
  margin: 20px 0;
  border-radius: 3px;
}

.appt-chart-header {
  background-color: $blue;
  color: white;
  padding: 10px;
  font-weight: bold;
  margin: 20px 0;
  border-radius: 3px;
}

.card {
  margin-bottom: 8px;
}

.blocks-cards {
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 20px;
}

/* Display a basic "tag" type of look for any tests
 that have been requested for a user on the calendar
 views */
/* This is originally from tables.scss */
.table-test {
  display: inline-block;
  font-size: 0.6rem;
  text-align: center;
  background-color: #f8c161;
  border: thin solid #f39f2f;
  margin: 0 2px 2px 0;
  padding: 2px;
  color: #000000;
}

.table-test:hover {
  cursor: help;
}


// calendar icon colors
.calendar-form--blue {
  color: $blue;

  &:hover {
    color: $blue-s--15;
  }
}

.calendar-form--green {
  color: $green;

  &:hover {
    color: $green-s--15;
  }
}

.calendar-form--red {
  color: $red;

  &:hover {
    color: $red-s--15;
  }
}

.calendar-form--orange {
  color: $orange;

  &:hover {
    color: $orange-s--15;
  }
}

.calendar-icon {
  font-size: 1.125rem;
  margin-right: 0.625rem;
}

// glyphicon padding
.form-table-status, .form-group {
  .glyphicon {
    padding-right: 1px;
  }
}

.calendar-form-radio-button {
  display: inline-block;
  margin-right: 10px;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%; /* Makes it a circle */
  display: inline-block;
  margin-right: 2px; /* Add spacing between circle and event title */
}

// Some css to support colors in old calendars
/**
 * Styling for appointments
 */

.table-appt{}
.table-appt--draft{}
.table-appt--upcoming{background-color: $appt-upcoming;}
.table-appt--attended{background-color: $appt-attended;}
.table-appt--cancelled{background-color: $appt-cancelled;}
.table-appt--noshow{background-color: $appt-noshow;}
.table-appt--blocked{background-color: $appt-blocked;}

/*
  Table Color Legend
 */
.table {
  .upcoming {
    background: $appt-upcoming;
  }
  .participant-intake-completed {
    background: $participant-intake-completed;
  }
  .attended {
    background: $appt-attended;
  }
  .cancelled {
    background: $appt-cancelled;
  }
  .noshow {
    background: $appt-noshow;
  }
  .mgmt_id_fail {
    background: $appt-mgmt_id_fail;
  }
  .blocked {
    background: $appt-blocked;
  }
}
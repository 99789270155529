// Original code: https://medium.com/@beyondborders/building-a-responsive-progress-bar-ea5a0ecabe91
// Built by Michael X
// Modified by Storm K


// Colours -----------------------------------------
$grey:  #777;
$grey2: #dfe3e4;
$blue:  #2183dd;
$green: #009900;
$white: #fff;
$red: #99150e;
$yellow: #ffc400;


// Progress Bar ------------------------------------
.status-wrapper {
  padding: 0px 5% 0px 5%;
  animation: fadein 1s forwards;
}

.status-bar-collection-site {
  padding-top: 10px;
  padding-bottom: 0.8em;
  font-size: 0.8em;
  font-weight: bold;
}

.status {
  position: relative;
  display: flex;

  // Background Track
  .status-track {
    position: absolute;
    top: 5px;
    width: 100%;
    height: 5px;
    background-color: $grey2;
    z-index: 0;
  }

  // Each Step on the Progress Bar
  .status-step {
    position: relative;
    width: 100%;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    padding: 0 3px;

    // Step's circle in default state
    &:before {
      content: "";
      display: flex;
      margin: -6px auto 10px;
      width: 30px;
      height: 30px;
      background: $white;
      border: 5px solid $grey2;
      border-radius: 100%;
      color: $white;
    }

    // Step's status bar in default state
    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 50%;
      width: 0%;
      transition: width 1s ease-in;
      height: 5px;
      background: $grey2;
      z-index: -1;
    }

    // Step's active state
    &.is-active {
      color: $blue;
      z-index: 1;

      &:before {
        border: 5px solid $grey;
        animation: pulse 2s infinite;
      }
    }

    // Step's complete state
    &.is-complete {
      color: $green;
      z-index: 1;

      // Step's circle in complete state
      &:before {
        content: '\e013';
        font-family: Glyphicons Halflings;
        font-size: 18px;
        line-height: 21px;
        color: $white;
        background: $green;
        border: 5px solid transparent;
      }

      // Step's status bar in complete state
      &:after {
        background: $blue;
        animation: nextStep 0.7s;
        animation-fill-mode: forwards;
        z-index: -1;
      }

      // Step's status bar in complete state - LAST STATUS BAR
      &:last-child:after {
        background: $blue;
        animation: lastStep 0.7s;
        animation-fill-mode: forwards;
      }

      // Fancy animation delay when loading page
      @for $i from 3 to 10 { // 3 to 10 just because it works
        &:nth-child(#{$i}):after {
          animation-delay: ($i - 1.5) * 0.4s;
        }
      }
    }

    // Step's incomplete state
    &.is-incomplete {
      color: $red;

      // Step's circle in incomplete state
      &:before {
        content: '\e014';
        font-family: Glyphicons Halflings;
        font-size: 19px;
        line-height: 21px;
        color: $white;
        background: $red;
        border: 5px solid transparent;
        width: fit-content;
      }
    }

    // Step's warning state
    &.is-warning {
      color: $yellow;

      // Step's circle in warning state
      &:before {
        content: '\e107';
        font-family: Glyphicons Halflings;
        font-size: 19px;
        line-height: 19px;
        color: $white;
        background: $yellow;
        border: 5px solid transparent;
      }

    }
  }

  #step0 {
    content: "";
    position: absolute;
    top: 5px;
    transition: width 1s ease-in;
    height: 5px;
    background: $grey2;
    z-index: 0;
    background: $blue;
    animation: firstStep 0.7s;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
  }
}

// Animations --------------------------------------
// Pulse animation for Step's circle in active state
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33,131,221, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(33,131,221, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33,131,221, 0);
  }
}

// Progressing to next step animation for Step's status bar
@keyframes firstStep {
  0% { width: 0%; }
  100% { width: 10.5%; }
}

@keyframes nextStep {
  0% { width: 0%; }
  100% { width: 100%; }
}

@keyframes lastStep {
  0% { width: 0%; }
  100% { width: 50%; }
}

// Button
#status_bar_next {
  right: 50px;
  bottom: 20px;
  cursor: pointer;
}

// No Select
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
} // Thanks Stack Overflow https://stackoverflow.com/a/4407335/6903181

.status-bar-comments > * {
  animation: fadein 1s forwards;
}

.status-bar-comments {
  margin-bottom: 10px;
}

.status-bar-comments-table {
  font-size: 0.8rem;
  line-height: 20px;
  width: 75%;
  margin: 0 auto;

  th {
    text-align: center;
    line-height: 20px;
    width: fit-content;
  }

  td {
    text-align: center;
    line-height: 20px;
    padding: 5px 10px;
    width: fit-content;
  }
}

.status-comment-header {
  text-align: center;
  margin: 10px auto;
  width: fit-content;
  border-radius: 5px;
}

@font-face {
  font-family: 'TofinoPersonal-Medium';
  src: url('/TofinoPersonal-Medium.eot?#iefix') format('embedded-opentype'), url('/TofinoPersonal-Medium.otf') format('opentype'),
    url('/TofinoPersonal-Medium.woff') format('woff'), url('/TofinoPersonal-Medium.ttf') format('truetype'), url('/TofinoPersonal-Medium.svg#TofinoPersonal-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'TofinoPersonal-Regular';
  src: url('/TofinoPersonal-Regular.eot?#iefix') format('embedded-opentype'), url('/TofinoPersonal-Regular.otf') format('opentype'),
    url('/TofinoPersonal-Regular.woff') format('woff'), url('/TofinoPersonal-Regular.ttf') format('truetype'), url('/TofinoPersonal-Regular.svg#TofinoPersonal-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'TofinoPersonal-Book';
  src: url('/TofinoPersonal-Book.eot?#iefix') format('embedded-opentype'), url('/TofinoPersonal-Book.otf') format('opentype'),
    url('/TofinoPersonal-Book.woff') format('woff'), url('/TofinoPersonal-Book.ttf') format('truetype'), url('/TofinoPersonal-Book.svg#TofinoPersonal-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gilmer-Heavy';
  src: url('/Gilmer-Heavy.eot?#iefix') format('embedded-opentype'), url('/Gilmer-Heavy.otf') format('opentype'),
    url('/Gilmer-Heavy.woff') format('woff'), url('/Gilmer-Heavy.ttf') format('truetype'), url('/Gilmer-Heavy.svg#Gilmer-Heavy') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gilmer-Regular';
  src: url('/Gilmer-Regular.eot?#iefix') format('embedded-opentype'), url('/Gilmer-Regular.otf') format('opentype'),
    url('/Gilmer-Regular.woff') format('woff'), url('/Gilmer-Regular.ttf') format('truetype'), url('/Gilmer-Regular.svg#Gilmer-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gilmer-Medium';
  src: url('/Gilmer-Medium.eot?#iefix') format('embedded-opentype'), url('/Gilmer-Medium.otf') format('opentype'),
    url('/Gilmer-Medium.woff') format('woff'), url('/Gilmer-Medium.ttf') format('truetype'), url('/Gilmer-Medium.svg#Gilmer-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'Gilmer-Bold';
  src: url('/Gilmer-Bold.eot?#iefix') format('embedded-opentype'), url('/Gilmer-Bold.otf') format('opentype'),
    url('/Gilmer-Bold.woff') format('woff'), url('/Gilmer-Bold.ttf') format('truetype'), url('/Gilmer-Bold.svg#Gilmer-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
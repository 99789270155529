// Theme colors
#main-wrapper {

	&[data-layout="vertical"],
	&[data-layout="horizontal"] {
		.topbar .top-navbar .navbar-header {

			&[data-logobg="skin6"] {
				background: $skin6;

				.navbar-brand {
					.dark-logo {
						display: inline;
					}

					.light-logo {
						display: none;
					}
				}

				.nav-toggler,
				.topbartoggler {
					color: $body-color;
				}
			}
		}

		.topbar .navbar-collapse,
		.topbar {
			&[data-navbarbg="skin6"] {
				background: $body-bg;
			}
		}

		/*This is for the sidebar bg*/
		.left-sidebar {
				&[data-sidebarbg="skin6"] {
					background: $skin6;

					.sidebar-nav {
						ul {
							.list-divider {
								background: $sidebar-text-dark;
							}

							.sidebar-item {
								.sidebar-link {
									color: $sidebar-text-dark;

									.feather-icon,
									i {
										color: $sidebar-icons-dark;
									}
								}

								.first-level {
									.sidebar-item.active {
										>.sidebar-link.active,
										>.sidebar-link.active>i {
											color: $dark;
										}
									}
								}

								&.selected>.sidebar-link {
									.feather-icon,
									i {
										color: $white;
									}
								}


							}

							.nav-small-cap {
								color: $black;
							}
						}

						.has-arrow {
							&::after {
								border-color: $sidebar-text-dark;
							}
						}

						.user-content,
						.user-name,
						.user-email {
							color: $theme-dark;
						}
					}

					.sidebar-nav li.selected>.has-arrow::after,
					.sidebar-nav li.selected>.has-arrow.active::after {
						border-color: $gray-0;
					}
				}
		}
	}
}

// Colors for appointment background coloring
$appt-attended     : #73D0A7;
$appt-upcoming     : #FCDFB5;
$participant-intake-completed: #ffb366;
$appt-cancelled    : #A0B6FB;
$appt-noshow       : #F08498;
$appt-blocked      : #7d1800;
$appt-refusal      : #d99066;
$appt-mgmt_id_fail : #E8EAEC;
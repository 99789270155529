.alert-headers {
      background-color: #4d4d4d;
      color: #FFFFFF;
      padding: 0.5rem;
      margin-bottom: 1rem;
  }

  .subcategory-headers {
    background-color: #2d2e3b;
    color: #FFFFFF;
    padding: 3px;
    margin-bottom: 1.5px;
    font-size: 14px;
    cursor: pointer;
  }

  .unread-notification-badge {
    float: right;
    margin-right: 20px;
  }

  .notification-link-header {
    font-weight: bold;
  }
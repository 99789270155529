// Placeholder Gray Color
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $gray-500;
            opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $gray-500;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
            color: $gray-500;
 }

// *************************************************
// Custom Select Menu
// *************************************************
.custom-select {
    background: $custom-select-indicator right 1.1rem center no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    background-size: auto;
}

.custom-select-set {
    height: 44px;
    line-height: 25px;
    padding: 10px 45px 13px 30px;
}

.dropdown-toggle::after {
    display: none;
}

// Form row Separator

.r-separator .form-group {
    border-bottom: 1px solid $border-color;

    &:last-child {
        border-bottom: none;
    }
}

.striped-rows .row:nth-child(odd) {
    background-color: $gray-100;
}

.b-form .row {
    border-bottom: 1px solid $border-color;
    margin-bottom: 0;
    padding: 15px 0;

    &:last-child {
        border-bottom: none;
    }
}

.b-label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.help-block{
  line-height: 1.3;
  font-style: italic;
  font-size: rem(12);
  margin-bottom: 0;
  margin-top: 5px;
}

.error-field-back-to-top {
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem;
  color: #a94442;
  margin-bottom: 0;
}

.error-field-back-to-top:hover {
  color: #E94442;
  text-decoration: none;
}

/* Custom form group CSS */
.form-group{
  @include clearfix;

  ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li{
    display: inline-block;
    margin-right: 5px;
  }

  &.display-block {
    li{
      display: block;
      margin-right: 0;
    }
  }

  input[type="radio"],
  input[type="checkbox"]{
    display: inline-block;

    &.form-control{
      width: auto;
      border: none;
      height: auto;
      vertical-align: middle;
      margin: 0;
    }
  }

  input[type="file"] {
    display: inline-block;

    &.form-control{
      width: auto;
      box-shadow: unset;
      border: none;
      height: auto;
      vertical-align: middle;
      margin: 0;
    }
  }

  label{
    font-size: rem(14);
  }

  small{
    font-size: rem(12);
  }

  .alert{
    font-size: rem(14);
    padding: 10px 35px 10px 10px;
  }
}

/**
 * Tests Checkboxes, Groupings, and Titles
 */
.form-testsHeading{
  margin: 1rem 0 0.4rem;
  padding-bottom: 3px;
  border-bottom: thin solid $gray-400;

  &:first-child{
    margin-top: 0.5rem;
  }
}

.form-testsGrouping{
  @include clearfix;

  input[type="checkbox"]{
    vertical-align: middle;
    width: 13px;
    height: 13px;
    margin: 0;
    padding: 0;
    position: relative;
    top: -1px;
    *overflow: hidden;
  }

  label{
    font-weight: normal;
    font-size: rem(13);
    display: block;
    float: left;
    width: 32%;
    line-height: 1.5;
    margin: 0 0 8px;
    padding-left: 15px;
    text-indent: -15px;
    vertical-align: top;
  }
}

/** Admin Panels */
.panel-item .panel {
  margin-bottom: -1px;
  border: 1px solid $gray-300;
}

// Just gives our submit buttons a bit of breathing space
.form-submitButtons{
  margin: 1rem 0;
}

/* Special Instructions */
.form-special-instructions {
  font-size: $font-size-sm;

  p {
    margin-bottom: 0;
  }
}
/* Special Instructions Highlights */
.redactor-formatting-div-marker,
.marker{
  padding: .2rem .4rem;
  display: block;
  line-height: 1;
  border-radius: 2px;
  text-decoration: none;
}

.marker--yellow:not(a),
.redactor-editor p.marker--yellow,
.redactor-formatting-div-marker.marker--yellow {
  background-color: #ffd61e;
  color: $gray-900;

  a {
    font-weight: bold;
    color: $gray-900;
    &:hover {
      text-decoration: underline;
    }
  }
}

.marker--red:not(a),
.redactor-editor p.marker--red,
.redactor-formatting-div-marker.marker--red {
  background-color: $red;
  color: $white;

  a {
    font-weight: bold;
    color: $white;
    &:hover {
      text-decoration: underline;
    }
  }
}

.marker--blue:not(a),
.redactor-editor p.marker--blue,
.redactor-formatting-div-marker.marker--blue {
  background-color: $blue;
  color: $white;

  a {
    font-weight: bold;
    color: $white;
    &:hover {
      text-decoration: underline;
    }
  }
}

/* Result Add/Edit Page Form CSS */
[role="button"] {
    cursor: pointer;
}
// Reduce table font size
.table-condensed {
  font-size: 0.8rem;
}

/*******************
Table Cell
*******************/
.no-wrap {

    td,
    th {
        white-space: nowrap;
    }
}

.table-header {
  font-weight: bold !important;
  text-transform: uppercase;
}

.table thead th,
.table th {
    font-weight: 500;
}

.nowrap {
    white-space: nowrap;
}

.v-middle {

    td,
    th {
        vertical-align: middle;
    }
}

.dataTables_wrapper {
    padding: 0;
}


/**
 * Availability Calendar Table Styles
 */
.table > thead > tr > th.table-availability,
.table > thead > tr > td.table-availability,
.table > tbody > tr > th.table-availability,
.table > tbody > tr > td.table-availability,
.table > tfoot > tr > th.table-availability,
.table > tfoot > tr > td.table-availability,
.table-condensed > thead > tr > th.table-availability,
.table-condensed > thead > tr > td.table-availability,
.table-condensed > tbody > tr > th.table-availability,
.table-condensed > tbody > tr > td.table-availability,
.table-condensed > tfoot > tr > th.table-availability,
.table-condensed > tfoot > tr > td.table-availability{
  vertical-align: top;
  padding: 3px 1px;
  font-size: 0.8rem;
}

/**
 * Book Appt Check Availability Modal Calendar Styles
 */
.calendar-timeslot, .table-appt {
  font-size: rem(13);
}

.table-appt {
  color: $dark;
}

.calendar-name{
  small{
    display: block;
  }
}

/* Object History */
table#change-history tbody th, table#change-history tbody td {
  font-size: 13px;
  word-break: break-word;
}

table#change-history tbody th {
  width: auto;
}
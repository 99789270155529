.beautify-marker {
  text-align: center;
  font-weight: 700;
  font-family: monospace;
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.beautify-marker.circle {
  border-radius: 100%;
}

.beautify-marker.circle-dot, .beautify-marker.doughnut {
  border-radius: 100%;
}

.beautify-marker.marker {
  border-top-left-radius: 50%;
  border-top-right-radius: 50% 100%;
  border-bottom-left-radius: 100% 50%;
  border-bottom-right-radius: 0%;
  /* rotating 45deg clockwise to get the corner bottom center */
  transform: rotate(45deg);

}

.beautify-marker.marker > * {
  /* rotating 45deg counterclock to adjust marker content back to normal */
  transform: rotate(-45deg);
}
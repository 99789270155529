/* This was from the changes.css css that Bill edited, yeah I know it's not sass, sorry haha -Storm */
.sidebarMenu {
  transform: translateX(-100%);
  transition: transform, 0.5s;
  background-color: #ffffff;
  padding: 0;
  position: fixed;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1000; }
  .sidebarMenu.is-open {
    transform: translateX(0);
    width: 80%; /* Only applies to mobile devices */
  }

.sidebarMenu-wrapper {
  overflow-y: auto; }

.sidebarMenu-toggle {
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 2.8125rem;
  height: 2.8125rem;
  font-size: 1.75rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  background-color: #FFB900; }
  .sidebarMenu-toggle:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6);
    background-color: #ffd900; }
  .sidebarMenu-toggle .glyphicon {
    padding-top: 7px; }

.is-open .sidebarMenu-toggle {
  background-color: #FFB900; }

.sidebarMenu-list, .sidebarMenu-list > ul {
  margin: 0;
  padding: 0; }

.sidebarMenu-item,
.sidebarMenu-childItem {
  list-style-type: none;
  font-weight: bold;
  font-size: 0.875rem; }

.sidebarMenu-item {
  position: relative;
  border-bottom: thin solid #b9b9b9; }

.sidebarMenu-itemToggle {
  transition: background-color 0.25s, color 0.25s;
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  float: right;
  color: #ffffff;
  background-color: rgba(175, 175, 175, 0.5);
  text-align: center;
  width: 2.1875rem;
  height: 100%; }
  .sidebarMenu-itemToggle:hover {
    background-color: #c3c3c3;
    cursor: pointer; }
    .sidebarMenu-itemToggle:hover:after {
      color: rgba(255, 255, 255, 0.6); }
  .sidebarMenu-itemToggle:after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -6px;
    content: "\25bc"; }

.is-active .sidebarMenu-itemToggle:after {
  content: "\25b2"; }

.sidebarMenu-childItem {
  font-size: 0.75rem;
  border-bottom: thin solid rgba(175, 175, 175, 0.5);
  padding-left: 0;
}

.sidebarMenu-link {
  transition: background-color 0.25s, color 0.25s;
  display: block;
  color: #949494;
  padding: 0.5rem 0 0.5rem 1rem;
  line-height: 1.4; }
  .sidebarMenu-link:hover, .sidebarMenu-childItem.is-active > .sidebarMenu-link {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    background-color: #adadad; }

.sidebarMenu-link--child {
  padding: 0.5rem 0 0.5rem 1.5rem;
}
//-----------------------------------------------------------
// GLOBAL
//-----------------------------------------------------------

// In case someone is on IE8 or lower
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body{
  background-color: $white;
  // cascade svg fill color
  // @props: https://css-tricks.com/cascading-svg-fill-color/
  fill: currentColor;
}

*:focus {
  outline: none;
}
.list{
  list-style-type: none;
}

.list--inline{
  display: inline-block;

  > li {
    display: inline-block;
  }
}

/**
 * Shortcuts
 */
.list-shortcuts{
  @extend .list--inline;
  margin: 0;
  padding: 0;
}

.list-shortcut-item{
  font-size: rem(12);
  margin-right: 5px;
  position: relative;

  &:after{
    position: absolute;
    top: 0;
    right: -7px;
    content: "|";
  }

  &:last-child:after{
    content: none;
  }
}


/**
 * Linked Test Results
 */
.list-linkedTests{
  @extend .list--inline;
  margin: 0;
  padding: 0;
}

.list-linkedTests-item{
  @extend .list-shortcut-item;
}

/**
 * Definition Lists
 */

.dlist{
  margin: 0;
}

.dlist.container{
  margin: 0;
  width: 100%;
  margin-top: 1rem;
}

.dlist-wrapper{
  margin-bottom: 1.5rem;

  &:nth-child(3n+1) {
    clear: left;
  }
}

.dlist-dt{
  font-size: 1rem;
  padding-top: 1rem;
}

.dlist-dd{
  font-size: 1rem;
  padding: 0;
}

.dlist-footnote{
  font-style: italic;
  font-size: rem(11);
}
/* Bootstrap support issue
@media (min-width: $screen-sm-min){
  .dlist-dd-sm--noBorder{
    border-top: none;
  }
}
*/

.unordered-detail{
  margin-left: 1.5rem;
}

/**
 * Delete List (popup windows from change_form template)
 */
.list-delete{
  padding-left: 2rem;

  ul{
    padding-left: 1.25rem;
  }
}

.participantListStyle{
  background-color: white;
  list-style: none;
  border-style: solid;
  border-width: 1px;
  font-size: 0.75em;
  position: absolute;
  padding: 1em;
  column-count: 3;
}

.participantListStyle li {
  background-color: white;
  margin-left: auto;
  line-height: 1.5em;
  border-bottom: 1px solid black;
  break-inside: avoid-column;
}

.participantListStyle li:hover {
  background-color: lightgrey;
  color: black;
}

.ui-autocomplete {
  z-index: 100 !important;
}

.ui-helper-hidden-accessible {
    display: none;
}

#toggle_emails_button {
  left: -0.5rem;
}

#toggle_button_text {
  font-size: 0.75rem;
  font-style: italic;
  color: #737373;
  display: inline;
}

#emails_container {
  margin-bottom: 1rem;
}

//-----------------------------------------------------------
// COLOR
//-----------------------------------------------------------
// Colors specific to this project

//
// Primary Brand Colors
// ------------------------------------------
$white             : #ffffff;
$light-grey        : #f3f3f3;
  $light-grey-s--15: shade($light-grey, 15%);

$medlt-grey        : #cccccc;

$med-grey          : #8B959E;
  $med-grey-t--30  : tint($med-grey, 30%);
$dark-grey         : #444444;
  $dark-grey-t--20 : tint($dark-grey, 20%);
$light-black       : #222222;
$black             : #000000;
$soft-grey         : #dddddd;


$orange            : #f39f2f;
  $orange-t--30    : tint($orange, 30%);
  $orange-s--15    : shade($orange, 15%);

$brown             : #83541e;

$blue              : #26afd9;
  $blue-s--15      : shade($blue, 15%);

$green             : #00A000;
  $green-s--15     : shade($green, 15%);

$red               : #cc3b3b;
  $red-s--15       : shade($red, 15%);
  $red-t--30       : tint($red, 30%);

$purple            : #800080;
  $purple-s--15    : shade($purple, 15%);
  $purple-t--30    : tint($purple, 30%);

// Colors for appointment background coloring
$appt-attended     : tint($green, 50%);
$appt-upcoming     : tint($orange, 50%);
$appt-cancelled    : tint($blue, 50%);
$appt-noshow       : tint($red, 65%);
$appt-blocked      : #7d1800;
$appt-refusal      : #d99066;
$appt-mgmt_id_fail : #b797e3;
// originals
// $appt-attended     : #dff0d8;
// $appt-upcoming     : #fcf8e3;
// $appt-cancelled    : #d9edf7;
// $appt-noshow       : #f2dede;
// $appt-blocked      : #7d1800;
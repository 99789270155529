/*******************
 Breadcrumb and page title
*******************/

.page-breadcrumb {
    padding: 15px 15px 5px;
    border-bottom: 1px solid #edf2f9;
    background-color: #ffffff;

    .breadcrumb {
        background: transparent;
        font-size: .875rem;

        .breadcrumb-item+.breadcrumb-item::before {
            color: $gray-400;
            font-size: 11px;
        }
        .breadcrumb-item a{
        	color: $breadcrumb-text;
        }
    }
}
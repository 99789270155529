* {
    outline: none;
}

body {
    margin: 0;
    overflow-x: hidden;
    color: $body-color;
    background: $body-bg;
}

html {
    position: relative;
    min-height: 100%;
}


a.link {
    color: $body-color;

    &:hover,
    &:focus {
        color: $themecolor;
    }
}

// For feather iconbar height-width
.svg-icon {
    height: 1.2em;
    width: 1.2em;
}

// Widgets doller minus margin
.set-doller {
    font-size: 17px;
    top: -9px;
    right: 2px;
}

// For header component like select & for search box in different color skin
.bg-transparent {
    background-color: transparent;
}

// shadow
.custom-shadow {
    box-shadow: $custom-shadow;
    -webkit-box-shadow: $custom-shadow;
    -moz-box-shadow: $custom-shadow;
}

.custom-radius {
    border-radius: $custom-radius;
}

// Opacity

.opacity-7 {
    opacity: 0.7;
}

// Fonts setting

html body {
    .font-weight-medium {
        font-weight: $font-weight-medium;
    }

    .font-10 {
        font-size: 10px;
    }

    .font-12 {
        font-size: 12px;
    }

    .font-14 {
        font-size: 14px;
    }

    .font-16 {
        font-size: 16px;
    }

    .font-18 {
        font-size: 18px;
    }

    .font-20 {
        font-size: 20px;
    }
}


/*******************
List-style-none
******************/

ul.list-style-none {
    margin: 0px;
    padding: 0px;

    li {
        list-style: none;

        a {
            color: $body-color;
            padding: 8px 0px;
            display: block;
            text-decoration: none;

            &:hover {
                color: $themecolor;
            }
        }
    }
}

b,
strong {
    font-weight: $font-weight-medium; // Add the correct font weight in Chrome, Edge, and Safari
}

// Icon Page Style
.f-icon,
.t-icon,
.w-icon,
.sl-icon,
.if-icon,
.m-icon {
    cursor: pointer;
    padding: 13px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background-color: $gray-100;
    }
}

.m-icon {
    width: 33%;
    display: inline-block;
}

@include media-breakpoint-down(sm) {
    .m-icon {
        width: 100%;
    }
}


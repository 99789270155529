@mixin login-container() {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;

    @include media-breakpoint-up(sm){
      padding: 0 30px;
    }
  }

  .l-sitewrap.login {
    font-family: 'Gotham SSm A', 'Gotham SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-image: url("../images/auth-bg.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .row {
      margin: 0;
    }

    #content {
      margin: 0;
      padding: 0;
    }

    .container-fluid {
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: none;
    }

    .login-container{
      padding: 0;
      position: relative;
    }
  }

  .login-container{
    padding-top: 100px;
  }

  .login-tabs {
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: center;
  }

  .login-tab {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 8px 15px;
    border: 1px solid white;
    width: 120px;
    transition: 0.2s;

    + .login-tab {
      margin-left: -1px;
    }

    &.is-active {
      background: white;

      &:hover {
        background: rgba(#fff, 1);
      }
    }

    img {
      display: block;
      width: 100%;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &:only-child {
      border-radius: 4px;
    }

    &:hover {
      background: rgba(#fff, 0.2);
      .login-soon {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
      }
    }
  }

  .login-soon {
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 2px;
    padding: 0 20px;
    display: flex;
    height: 42px;
    border-radius: 2px;
    box-shadow: 0 4px 9px 0 rgba(0,0,0,0.14);
    color: white;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    align-items: center;
    background-color: #032240;
    transition: 0.2s;
    transform: translateX(-50%) translateY(-50%);
    width: 210px;
    line-height: 1.1;
    opacity: 0;
    transition: 0.2s;

    &:before {
      content: "";
      position: absolute;
      top: -5px;
      left: 50%;
      border-bottom: 5px solid #032240;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }


  .login-panels {
    @include login-container;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm){
      flex-direction: row;
    }
  }

  .login-leftPanel{
    margin: 0 0 30px;
    padding: 25px 30px;
    position: relative;
    z-index: 2;
    width: 100%;
    background-color: white;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 25px 40px 0 rgba(3,34,64,0.2);

    @include media-breakpoint-up(sm){
      padding: 35px 30px;
      margin: 25% 25%;
      flex: 0 0 380px;
    }

    @include media-breakpoint-up(md){
      padding: 35px 50px;
      margin: 25% 25%;
      flex: 0 0 475px;
    }
  }

  .login-title {
    margin-bottom: 15px;
    color: #231F20;
    font-size: 28px;
    font-weight: 300;

    @include media-breakpoint-up(sm){
      font-size: 34px;
    }
  }

  .login-introText{
    color: #576E74;
    font-size: 16px;
    line-height: 1.5;

    @include media-breakpoint-up(sm){
      font-size: 14px;
    }
  }

  .form--login{
    margin: 25px 0;
  }

  .login-input{
    margin: 0 auto 1rem;
    display: block;

    .form-control{
      float: none;
      width: 100%;
      font-size: 16px;
      height: 48px;
      display: flex;
      align-items: center;
      border-radius: 2px;
    }
  }

  .login-button {
    padding: 0 25px;
    display: flex;
    height: 48px;
    border-radius: 2px;
    box-shadow: 0 4px 9px 0 rgba(0,0,0,0.14);
    color: white;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #5f76e8;
    transition: 0.2s;
    width: 100%;
    border: none;
    outline: none;

    &:hover,
    &:focus:not(:active),
    &:active {
      background-color: mix(#fff, #4a4d72, 12%);
      color: #fff;
      transform: translateY(-2px);
    }
  }


  /**
   * Right Column
   */


  .login-sidebarTitle {
    color: white;
    font-size: 28px;
    font-weight: 300;
  }

  .login-sidebarText{
    color: white;
    opacity: 0.8;
    font-size: 14px;
    line-height: 1.5;

    @include media-breakpoint-up(md){
      font-size: 16px;
    }
  }

  .login-list{
    margin: 0 0 0.5rem 0;
    padding: 0;
  }

  .login-listItem{
    margin-left: 0.5rem;
    list-style-type: none;
    font-size: rem(14);

    &:before{
      content: '\2713';
      width: rem(20);
      height: rem(20);
      color: $orange;
      margin-right: rem(10);
    }
  }

  .login-sidebarHeader {
    padding: 0 15px;

    @include media-breakpoint-up(sm){
      padding: 0 0 0 30px;
    }

    @include media-breakpoint-up(md){
      padding: 0 0 0 50px;
    }
  }

  .login-sidebarButtons{
    margin: 0 0 0.5rem;
  }

  .login-learnMore{
    @include transition(color 0.25s);
    font-size: rem(13);
    color: $med-grey;

    &:hover{
      color: $med-grey-t--30;
      text-decoration: none;
    }
  }

  .login-link {
    height: 25px;
    opacity: 0.8;
    color: #576E74;
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
  }

  .login-or {
    width: 200px;
    margin: -10px auto 20px;
    border-bottom: 1px solid #CADFF2;
    text-align: center;

    span {
      color: #231F20;
      font-size: 12px;
      font-weight: 300;
      top: 15px;
      position: relative;
      display: inline-block;
      padding: 0 10px;
      background: white;
    }
  }


  .login-footer {
    padding: 25px 0;
    text-align: center;
    background: white;

    .login-footerContainer {
      @include login-container;
    }
  }

  .login-footerContent {
    p {
      line-height: 1.1;
    }
  }

  .login-footerText {
    margin: 8px 0;
    padding: 0;
    font-size: 14px;
    color: #6D6D6D;
  }

  .login-footerCopy {
    margin: 8px 0;
    padding: 0;
    font-size: 12px;
    color: #4A4A4A;

    a {
      display: inline-block;
      padding: 0 6px;
      color: #4A4A4A;
      font-weight: 600;
    }
  }

  .login-slides {
    margin: 0 -15px 20px;

    @include media-breakpoint-up(sm){
      margin: 0;
      position: absolute;
      bottom: 90px;
      left: 410px;
      right: 0;
    }

    @include media-breakpoint-up(md){
      margin: 0;
      position: absolute;
      bottom: auto;
      left: 46%;
      right: 0;
    }
  }

  .swiper-container {
    width: 100%;
  }

  #login-services {
    .icon {
      vertical-align: middle;
      width: 34px;
      height: 34px;

      svg {
        vertical-align: middle;
        fill: white;
        width: 34px;
        height: 34px;
      }
    }

    .icon--incircle {
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid white;

      svg {
        fill: none;
        stroke: white;
        stroke-width: 0.6px;
      }

      path,
      circle,
      rect,
      line,
      polylinei,
      polygon {
        vector-effect: non-scaling-stroke;
      }
    }

    .sl-cat {
      width: 300px;
      height: 310px;
      display: block;
      background-color: #3F9ACD;
      border-radius: 3px;

      @include media-breakpoint-up(md){
        width: 440px;
      }
    }

    .sl-cat-container {
      display: flex;
      margin: 15px;

      @include media-breakpoint-up(md){
        margin: 25px 30px;
      }
    }

    .sl-cat-icon {
      width: 34px;
      margin-right: 10px;
    }

    .sl-cat-main {
      flex: 1;
    }

    .sl-cat-heading {
      margin: 10px 0 15px;
      font-weight: 300;
      font-size: 16px;
      color: white;

      @include media-breakpoint-up(md){
        font-size: 18px;
        margin: 8px 0 15px;
      }
    }

    .sl-list {
      margin: 0;
      padding: 0;

      li {
        margin: 5px 0;
        padding: 0;
        line-height: 1.2;
        font-size: 14px;
        list-style: none;

        @include media-breakpoint-up(md){
          font-size: 15px;
          margin: 8px 0;
        }

        a {
          opacity: 0.8;
          color: white;
          display: block;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

//
// calendar.scss
//

.fc-view {
    margin-top: 15px;
}

.none-border {
    .modal-footer {
        border-top: none;
    }
}

.fc-toolbar {
    margin: 15px 0 0 0 !important;

    h2 {
        font-size: 1.25rem;
        line-height: 1.875rem;
        text-transform: uppercase;
    }
}

.fc-day-grid-event {
    .fc-time {
        font-weight: $font-weight-bold;
    }
}

.fc-day {
    background: transparent;
}

.fc-toolbar {

    .fc-state-active,
    .ui-state-active,
    button:focus,
    button:hover,
    .ui-state-hover {
        z-index: 0;
    }
}

.fc {
    th.fc-widget-header {
        background: lighten($gray-200, 3%);
        font-size: 13px;
        line-height: 20px;
        padding: 10px 0;
        text-transform: uppercase;
    }
}

.fc-unthemed {

    th,
    td,
    thead,
    tbody,
    .fc-divider,
    .fc-row,
    .fc-popover {
        border-color: $gray-100;
    }
}

.fc-button {
    background: lighten($gray-200, 3%);
    border: none;
    color: $gray-500;
    text-shadow: none;
    text-transform: capitalize;
    box-shadow: none;
    border-radius: 3px;
    margin: 0 3px;
    padding: 6px 12px;
    height: auto;
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 1rem;
}

.fc-state-hover {
    background: $gray-200;
}

.fc-state-highlight {
    background: $gray-300;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: $primary;
    color: $white;
    text-shadow: none;
}

.fc-cell-overlay {
    background: $gray-300;
}

.fc-unthemed {
    td.fc-today {
        background: lighten($gray-200, 5%);
    }

    .fc-divider,
    .fc-list-heading td,
    .fc-popover .fc-header {
        background: $gray-100;
    }
}


.fc-event {
    border-radius: 2px;
    border: none;
    cursor: pointer;
    font-size: 0.8125rem;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}

.external-event {
    cursor: move;
    margin: 10px 0;
    padding: 8px 10px;
    color: $white;
}

.fc-basic-view {
    td.fc-week-number {
        span {
            padding-right: 8px;
        }
    }

    td.fc-day-number {
        padding-right: 8px;
    }

    .fc-content {
        color: $white;
    }
}

.fc-time-grid-event {
    .fc-content {
        color: $white;
    }
}

.fc-ltr {
    .fc-basic-view {
        .fc-day-top {
            .fc-day-number {
                float: right;
                height: 20px;
                width: 20px;
                text-align: center;
                line-height: 20px;
                background-color: $gray-100;
                border-radius: 50%;
                margin: 5px;
                font-size: 11px;
            }
        }
    }
}

  .fc-scroller {
    overflow: auto !important;
  }

/* Mobile calendar top controls */
@include media-breakpoint-down(xs) {
    .fc-toolbar {
      flex-wrap: wrap;
      flex-direction: column;

        .fc-toolbar-chunk{
            margin: 10px auto;
        }
    }

    .fc {
        .fc-toolbar {
            >* {
                >* {
                    float: none;
                }
            }
        }
    }

  /* To make sure horizontally scrolling list table looks nice in mobile still */
  .fc-list-table {
    min-width: 500px;
  }

    /* Make sure list table is horizontally scrollable */
  .fc-scroller {
    overflow-x: scroll !important;
  }

}